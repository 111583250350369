import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL } from "@/const";

export const ErrorCode = {
  NotExistsError: "NotExistsError",
  NotMatchPrefecture: "NotMatchPrefecture",
  NotMatchAddress: "NotMatchAddress",
};

export default class AddressCheck {
  constructor(zipCode, prefecture, address) {
    this.zipCode = zipCode;
    this.prefecture = prefecture;
    this.address = address;
  }

  async isValid() {
    const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.zipCode}`;
    const zipCodeResult = await axios.get(url, {
      adapter: axiosJsonpAdapter,
    });
    const zipCodeResultData = zipCodeResult.data.results;
    if (zipCodeResultData != null && zipCodeResultData.length > 0) {
      const validPrefecture = zipCodeResultData.some((resultData) => {
        return resultData.address1 === this.prefecture;
      });
      if (!validPrefecture) {
        return ErrorCode.NotMatchPrefecture;
      }

      const validAddress = zipCodeResultData.some((resultData) => {
        return resultData.address2 === this.address;
      });

      if (!validAddress) {
        return ErrorCode.NotMatchAddress;
      }
    }

    return null;
  }
}
