import { parse, CsvError } from "csv-parse";
import AddressCheck, { ErrorCode } from "../../../../mixins/AddressCheck";
import { PROHIBITED_CHARACTERS_REGEX } from "@/const";

class CsvParseWrapper {
  constructor(prefs) {
    this.lineIndex = 0;
    this.errorMessageSize = 0;
    this.addErrorMessageFunc = () => {
      this.errorMessageSize = this.errorMessageSize + 1;
    };
    this.prefectures = prefs;
  }

  addErrorMessageHandler = (addErrorMessageFunc) => {
    this.addErrorMessageFunc = (msg) => {
      this.errorMessageSize = this.errorMessageSize + 1;
      addErrorMessageFunc(msg);
    };
  };

  onData = async (factoryStoreData) => {
    this.lineIndex = this.lineIndex + 1;
    if (factoryStoreData.length !== 9) {
      const msg =
        `${this.lineIndex}行目のフォーマットに誤りがあります。以下のフォーマットで作成して下さい。\n` +
        "事業所/事業場区分,事業所名,事業場名,郵便番号,都道府県,市区町村番地,建物名号室,電話番号,FAX番号";
      this.addErrorMessageFunc(msg);
      return;
    }
    // バリデーションチェック
    const data = {
      factoryStoreTypeId: factoryStoreData[0],
      factoryName: factoryStoreData[1],
      storeName: factoryStoreData[2],
      zipCode: factoryStoreData[3],
      prefecturesName: factoryStoreData[4],
      address: factoryStoreData[5],
      buildingName: factoryStoreData[6],
      tel: factoryStoreData[7],
      fax: factoryStoreData[8],
    };

    const isValid = await this.validateCSVData(data, this.lineIndex);

    if (isValid) {
      return data;
    } else {
      return null;
    }
  };

  onError = (err) => {
    if (err instanceof CsvError) {
      const msg =
        `${err.lines}行目のフォーマットに誤りがあります。以下のフォーマットで作成して下さい。\n` +
        "事業所/事業場区分,事業所名,事業場名,郵便番号,都道府県,市区町村番地,建物名号室,電話番号,FAX番号";
      this.addErrorMessageFunc(msg);
    }
  };

  onEnd = (completeFunc, errorFunc) => {
    return (err, records) => {
      if (err) {
        this.onError(err);
        errorFunc(err);
        return;
      }

      const validatedRecords = records.reduce((promise, record) => {
        return promise.then(async (records) => {
          const formData = await this.onData(record);
          return records.concat(formData);
        });
      }, Promise.resolve([]));
      validatedRecords.then((records) => {
        console.debug("records = ", records);
        // フォーム用データに加工
        if (this.errorMessageSize === 0) {
          const factoryList = records.filter(
            (data) => data.factoryStoreTypeId === "1"
          );
          const formData = factoryList.map((factory) => {
            const storeList = records.filter(
              (data) =>
                data.factoryStoreTypeId === "2" &&
                data.factoryName === factory.factoryName
            );
            const createGenerateFactory = {
              factoryName: factory.factoryName,
              createGenerateStoreList: storeList.map((store) => {
                return {
                  storeName: store.storeName,
                  addressInfo: {
                    zipCode: store.zipCode,
                    prefecturesId: this.prefectures.filter(
                      (p) => p.name === store.prefecturesName
                    )[0].id,
                    address: store.address,
                    buildingName: store.buildingName,
                  },
                  tel: store.tel,
                  fax: store.fax,
                };
              }),
            };
            return createGenerateFactory;
          });
          completeFunc(formData);
        } else {
          completeFunc(null);
        }
      });
    };
  };

  addValidateJustBlank = (validateJustBlankFunc) => {
    this.validateJustBlank = validateJustBlankFunc;
  };

  addValidateMaxLen = (validateMaxLenFunc) => {
    this.validateMaxLen = validateMaxLenFunc;
  };

  addValidateNumberMaxLen = (validateNumberMaxLenFunc) => {
    this.validateNumberMaxLen = validateNumberMaxLenFunc;
  };

  addValidateNumberEqualLen = (validateNumberEqualLenFunc) => {
    this.validateNumberEqualLen = validateNumberEqualLenFunc;
  };

  validateGenerateStore = async (index, data) => {
    let existsError = false;
    let existsZipcodeError = false;
    let existsPrefectureError = false;
    let existsAddressError = false;
    // 事業場名の禁則文字のチェック
    if (PROHIBITED_CHARACTERS_REGEX.test(data.storeName)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 登録できない文字が含まれています。\n`
      );
      existsError = true;
    }
    // 事業場名
    if (!data.storeName || !this.validateMaxLen(data.storeName, 100)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 事業場名は100文字以内で入力してください。\n`
      );
      existsError = true;
    }
    // 郵便番号
    if (!this.validateNumberEqualLen(data.zipCode, 7)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 郵便番号は7桁の半角数字(ハイフンなし)で入力してください。\n`
      );
      existsZipcodeError = true;
      existsError = true;
    }
    // 都道府県
    if (
      this.prefectures.filter((p) => p.name === data.prefecturesName).length !==
      1
    ) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 都道府県に誤りがあります。( ${data.prefecturesName} )\n`
      );
      existsError = true;
      existsPrefectureError = true;
    }
    // 市区町村番地
    if (!data.address || !this.validateMaxLen(data.address, 100)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 市区町村番地は100文字以内で入力してください。\n`
      );
      existsError = true;
      existsAddressError = true;
    }

    if (!existsZipcodeError && !existsPrefectureError && !existsAddressError) {
      try {
        const addressCheck = new AddressCheck(
          data.zipCode,
          data.prefecturesName,
          data.address
        );

        const isValid = await addressCheck.isValid();

        if (isValid != null) {
          if (isValid === ErrorCode.NotMatchPrefecture) {
            this.addErrorMessageFunc(
              `${index}行目エラー : 郵便番号と都道府県が一致していません。( ${data.prefecturesName} )\n`
            );
            existsError = true;
          }

          if (isValid === ErrorCode.NotMatchAddress) {
            this.addErrorMessageFunc(
              `${index}行目エラー : 郵便番号と市区町村が一致していません。`
            );
            existsError = true;
          }
        }
      } catch (err) {
        this.addErrorMessageFunc(`エラーが発生しました`);
        return false;
      }
    }

    // 建物名号室
    if (!!data.buildingName && !this.validateMaxLen(data.buildingName, 100)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 建物名号室は100文字以内で入力してください。\n`
      );
      existsError = true;
    }
    // 電話番号
    if (!data.tel || !this.validateNumberMaxLen(data.tel, 15)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 電話番号は15桁以内の半角数値(ハイフンなし)で入力してください。\n`
      );
      existsError = true;
    }
    // FAX番号
    if (!!data.fax && !this.validateNumberMaxLen(data.fax, 15)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : FAX番号は15桁以内の半角数値(ハイフンなし)で入力してください。( ${
          data.fax
        } ${data.fax ? data.fax.length : 0} )\n`
      );
      existsError = true;
    }
    return !existsError;
  };

  validateCSVData = async (data, index) => {
    let existsError = false;
    // 事業所/事業場区分
    if (!["1", "2"].includes(data.factoryStoreTypeId)) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 事業所/事業場区分は半角数字1:事業所、2:事業場どちらかで入力してください。\n`
      );
      existsError = true;
    }
    // 事業所情報
    if (
      !(
        this.validateJustBlank(data.factoryName) &&
        this.validateMaxLen(data.factoryName, 100)
      )
    ) {
      this.addErrorMessageFunc(
        `${index}行目エラー : 事業所名は100文字以内で入力してください。\n`
      );
      existsError = true;
    }
    // 事業場情報
    if (data.factoryStoreTypeId === "2") {
      const existsGenerateStoreError = !(await this.validateGenerateStore(
        index,
        data
      ));
      if (existsGenerateStoreError) {
        existsError = true;
      }
    }
    return !existsError;
  };

  process = (buffer, completeFunc, errorFunc) => {
    parse(buffer, this.onEnd(completeFunc, errorFunc));
  };
}

export default {
  data() {
    return {
      csvErrorMsgs: [[]],
    };
  },
  methods: {
    loadFunc(companyIndex) {
      return (e) => {
        // エラーメッセージの初期化
        this.csvErrorMsgs[companyIndex] = [];
        this.createGenerateCompanyList[companyIndex].createGenerateFactoryList =
          [];
        const parserWrapper = new CsvParseWrapper(this.prefectures);
        parserWrapper.addValidateJustBlank(this.validateJustBlank);
        parserWrapper.addValidateMaxLen(this.validateMaxLen);
        parserWrapper.addValidateNumberEqualLen(this.validateNumberEqualLen);
        parserWrapper.addValidateNumberMaxLen(this.validateNumberMaxLen);
        parserWrapper.addErrorMessageHandler((msg) => {
          this.csvErrorMsgs[companyIndex].push(msg);
        });

        parserWrapper.process(
          Buffer.from(e.target.result),
          (formData) => {
            if (formData != null) {
              this.createGenerateCompanyList[
                companyIndex
              ].createGenerateFactoryList = formData;
            }
            this.processing = false;
          },
          () => {
            this.processing = false;
          }
        );
      };
    },
    fileChange(e, companyIndex) {
      if (!e.target.files[0]) return;

      const file = e.target.files[0] || e.dataTransfer.files[0];
      if (!file.type.match("text/csv")) {
        alert("CSVファイルを選択してください");
        return;
      } else {
        this.csvFileName[companyIndex] = file.name;
      }

      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = this.loadFunc(companyIndex);
      this.processing = true;
    },
  },
};
