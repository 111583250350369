export default {
  data() {
    return {
      // post用のデータ
      createGenerateCompanyList: [
        {
          formId: 1, // form追加削除する用の値。postしない。post前に削除する
          generateContractId: this.contractId,
          companyName: "",
          addressInfo: {
            zipCode: "",
            prefecturesId: null,
            address: "",
            buildingName: "",
          },
          tel: "",
          fax: "",
          jwnetContractNo: "",
          ediKey: "",
          accountInfo: {
            accountName: "",
            accountMail: "",
          },
          createGenerateFactoryList: [
            {
              factoryId: 1,
              id: 1,
              isActive: false,
              name: "",
              generateStoreInfoList: [
                {
                  storeId: 1,
                  name: "",
                  zipCode: "",
                  prefecturesId: null,
                  prefecturesName: "",
                  address: "",
                  buildingName: "",
                  tel: "",
                  fax: "",
                }
              ],
            }
          ]
        },
      ], // /form
      duplicationNameCheckInfoList: [],
    };
  },
};
